<template>
  <div style="height: 100%">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top flex-space-between">
          <el-page-header @back="goBack" content="挂账详情"> </el-page-header>
        </div>
        <div class="table-line"></div>
        <div class="table-content">
          <div class="top-info flex-space-between">
            <div class="left-i">
              <div class="flex-start">
                <img v-if="patientInfo.sex" src="@/assets/img/touxiang03.png">
                <img v-else src="@/assets/img/touxiang04.png">
                <div class="userName">{{ detailInfo.patientName ? detailInfo.patientName : '无' }}</div>
              </div>
              <div class="info-div">
                <span class="t1"><span class="t2">性别：</span><span v-if="patientId">{{ patientInfo.sex == true ? '男' : '女'
                }}</span><span v-else>无</span></span>
                <span class="t1"><span class="t2">年龄：</span>{{ patientInfo.age ? patientInfo.age : '无' }}</span>
                <span><i class="el-icon-phone t2"></i>：{{ patientInfo.phone ? patientInfo.phone : '无' }}</span>
              </div>
            </div>
            <div class="center-i">
              <div class="t1">
                <span class="t2">就诊类型：</span>
                <template v-if="detailInfo.diagnoseType">复诊</template>
                <template v-else>初诊</template>
              </div>
              <div class="t1"><span class="t2">就诊时间：</span>{{ detailInfo.addTime | timefilters }}</div>
              <div class="t1"><span class="t2">看诊医生：</span>{{ detailInfo.doctorName ? detailInfo.doctorName : '无' }}</div>
            </div>
            <div class="right-i">
              <div class="t1"><span class="t2">审核医生：</span>无</div>
              <div class="t1"><span class="t2">审核时间：</span>无</div>
              <div class="t1"><span class="t2">诊断：</span>{{ detailInfo.diagnoseResult ? detailInfo.diagnoseResult : '无' }}
              </div>
            </div>
            <div class="right-img">
              <!-- <div class="t1"><span class="t2">总金额：</span>¥{{ detailInfo.totalPayMoney.toFixed(2) }}</div>
              <div class="t1"><span class="t2">优惠：</span>¥{{ detailInfo.discountsMoney.toFixed(2) }}</div>
              <div class="t1"><span class="t2">应收：</span>¥{{ (detailInfo.totalPayMoney -
                detailInfo.discountsMoney).toFixed(2) }}</div> -->
            </div>
          </div>
          <div class="numDiv flex-space-between">
            <template v-for="(item) in detailInfo.payments">
              <div class="flex-start">
                <div class="t1"><span class="t2">收费时间：</span>{{ item.paySuccessTime | timefilters }}</div>
                <div><span class="t2">收费员：</span>{{ item.userName }}</div>
              </div>
              <div class="flex-start center-num">
                <div class="t1"><span class="t2">总金额：</span>{{ item.totalMoney ? item.totalMoney.toFixed(2) : '0.00' }}
                </div>
                <div class="t1"><span class="t2">优惠：</span>¥{{ item.discountsMoney ? item.discountsMoney.toFixed(2) :
                  '0.00' }}</div>
                <div><span class="t2">应收：</span>¥{{ (item.totalMoney - item.discountsMoney).toFixed(2) }}</div>
              </div>
              <div class="flex-end right-num">
                <div class="t1">付款方式：<span class="t3" v-if="item.isDebt">欠款
                    ¥{{ item.debtMoney ? item.debtMoney.toFixed(2) : '0.00' }}</span> ;</div>
                <div class="t3">
                  <span v-if="item.payWay == 0">现金</span>
                  <span v-if="item.payWay == 1">微信</span>
                  <span v-if="item.payWay == 2">支付宝</span>
                  <span v-if="item.payWay == 3">银行卡</span>
                  <span v-if="item.payWay == 4">其他</span>
                  ¥{{ item.payMoney ? item.payMoney.toFixed(2) : '0.00' }}
                </div>
              </div>
            </template>
          </div>
          <div class="title" v-if="orderGrouped[2].length > 0">检查治疗项</div>
          <el-table :data="orderGrouped[2]" style="width: 100%;" :header-cell-style="{ background: '#F5F7FC' }"
            v-if="orderGrouped[2].length > 0">
            <el-table-column prop="entityName" label="检查名称"></el-table-column>
            <el-table-column prop="money" label="单价" align="center">
              <template slot-scope="scope">
                {{ scope.row.money.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="数量" align="center"></el-table-column>
            <el-table-column prop="countMoney" label="总金额（元）" align="center">
              <template slot-scope="scope">
                {{ scope.row.countMoney.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="addTime" label="状态" align="center" width="150px">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.orderState == 0" size="small">待支付</el-tag>
                <el-tag type="success" v-if="scope.row.orderState == 1" size="small">已支付</el-tag>
                <el-tag type="danger" v-if="scope.row.orderState == -1" size="small">已退费</el-tag>
              </template>
            </el-table-column>
          </el-table>
          <div class="title" v-if="orderGrouped[1].length > 0">处方药</div>
          <el-table :data="orderGrouped[1]" style="width: 100%;" :header-cell-style="{ background: '#F5F7FC' }"
            v-if="orderGrouped[1].length > 0">
            <el-table-column prop="prescriptionType" label="处方类型">
              <template slot-scope="scope">
                <p v-if="scope.row.prescriptionType == 0">方剂处方</p>
                <p v-if="scope.row.prescriptionType == 1">成药处方</p>
                <p v-if="scope.row.prescriptionType == 2">输液处方</p>
              </template>
            </el-table-column>
            <el-table-column prop="entityName" label="药品名称" align="center"></el-table-column>
            <el-table-column prop="specification" label="规格包装" align="center">
              <template slot-scope="scope">
                <p v-if="scope.row.specification">
                  {{ scope.row.specification }}/{{ scope.row.specificationUnit }}
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="productFactory" label="厂家" align="center"></el-table-column>
            <el-table-column prop="money" label="单价" align="center">
              <template slot-scope="scope">
                {{ scope.row.money.toFixed(2) }}/{{ scope.row.isSeparate ? scope.row.separateNumberUnit :
                  scope.row.specificationUnit }}
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="数量" align="center"></el-table-column>
            <el-table-column prop="countMoney" label="总金额（元）" align="center">
              <template slot-scope="scope">
                {{ scope.row.countMoney.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="dosage" label="剂数" align="center">
              <template slot-scope="scope">
                {{ scope.row.dosage?scope.row.dosage:''}}
              </template>
            </el-table-column>
            <el-table-column prop="addTime" label="状态" align="center" width="150px">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.orderState == 0" size="small">待支付</el-tag>
                <el-tag type="success" v-if="scope.row.orderState == 1" size="small">已支付</el-tag>
                <el-tag type="danger" v-if="scope.row.orderState == -1" size="small">已退费</el-tag>
              </template>
            </el-table-column>
          </el-table>
          <div class="title" v-if="orderGrouped[0].length > 0">直接售药</div>
          <el-table :data="orderGrouped[0]" style="width: 100%;" :header-cell-style="{ background: '#F5F7FC' }"
            v-if="orderGrouped[0].length > 0">
            <el-table-column prop="entityName" label="药品名称"></el-table-column>
            <el-table-column prop="name" label="规格包装" align="center">
              <template slot-scope="scope">
                <p v-if="scope.row.specification">
                  {{ scope.row.specification }}/{{ scope.row.specificationUnit }}
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="productFactory" label="厂家" align="center"></el-table-column>
            <el-table-column prop="money" label="单价" align="center">
              <template slot-scope="scope">
                {{ scope.row.money.toFixed(2) }}/{{ scope.row.isSeparate ? scope.row.separateNumberUnit :
                  scope.row.specificationUnit }}
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="数量" align="center"></el-table-column>
            <el-table-column prop="countMoney" label="总金额（元）" align="center">
              <template slot-scope="scope">
                {{ scope.row.countMoney.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="addTime" label="状态" align="center" width="150px">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.orderState == 0" size="small">待支付</el-tag>
                <el-tag type="success" v-if="scope.row.orderState == 1" size="small">已支付</el-tag>
                <el-tag type="danger" v-if="scope.row.orderState == -1" size="small">已退费</el-tag>
              </template>
            </el-table-column>
          </el-table>
          <div class="title" v-if="orderGrouped[3].length > 0">附加收费</div>
          <el-table :data="orderGrouped[3]" style="width: 100%;" :header-cell-style="{ background: '#F5F7FC' }"
            v-if="orderGrouped[3].length > 0">
            <el-table-column prop="entityName" label="费用名称"></el-table-column>
            <el-table-column prop="countMoney" label="金额（元）" align="center">
              <template slot-scope="scope">
                {{ scope.row.countMoney.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="addTime" label="状态" align="center" width="150px">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.orderState == 0" size="small">待支付</el-tag>
                <el-tag type="success" v-if="scope.row.orderState == 1" size="small">已支付</el-tag>
                <el-tag type="danger" v-if="scope.row.orderState == -1" size="small">已退费</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Outpatient } from "@/components/DrugDomain/Outpatient"
import { Patient } from "@/components/HospitalDomain/Patient/Patient"
export default {
  data () {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    var patient = new Patient(this.TokenClient, this.Services.Authorization)
    return {
      OutpatientDomain: outpatient,
      patientDomain: patient,
      tableData: [],
      detailInfo: {
        gender: 0,
      },
      orderId: this.$route.query.orderId,
      orderGrouped: { 0: [], 1: [], 2: [], 3: [] },
      patientId: 0,
      patientInfo: {}
    }
  },
  mounted () {
    this.debtDetail()
  },
  methods: {
    debtDetail () {
      var _this = this
      _this.OutpatientDomain.DebtDetail(this.orderId,
        function (data) {
          _this.detailInfo = data.data
          _this.patientId = _this.detailInfo.patientId
          if (_this.patientId) {
            _this.getDetail()
          }
          //orderType 0直接售药 1处方 2检查治疗项 3附加收费
          _this.orderGrouped = { 0: [], 1: [], 2: [], 3: [] }
          if (_this.detailInfo.details.length > 0) {
            _this.detailInfo.details.map(({ orderType, ...rest }) => {
              _this.orderGrouped[orderType] = _this.orderGrouped[orderType] || []
              rest.orderType = orderType
              if ((orderType == 0 || orderType == 1) && _this.detailInfo.mzDrugOrgs) {
                let drugOrg = _this.detailInfo.mzDrugOrgs.filter(i => i.id == rest.entityId)
                if (drugOrg[0]) {
                  rest.specification = drugOrg[0].mzDrug.specification
                  rest.specificationUnit = drugOrg[0].mzDrug.specificationUnit
                  rest.productFactory = drugOrg[0].mzDrug.productFactory
                  rest.isSeparate = drugOrg[0].isSeparate
                  rest.separateNumber = drugOrg[0].separateNumber
                  rest.separateNumberUnit = drugOrg[0].separateNumberUnit
                }
              }
              _this.orderGrouped[orderType].push(rest)
            })
          }
        },
        function (err) {
          console.log(err)
        }
      )
    },
    getDetail () {
      var _this = this
      _this.patientDomain.GetDetail(_this.patientId,
        function (data) {
          _this.patientInfo = data.data
        },
        function (err) {

        })
    },
    goBack () {
      this.$router.go(-1)
    },
  },
}
</script>
<style scoped lang="scss">
.iq-card-body {
  padding: 0 0 15px;
  height: auto;

  .table-top {
    width: 96%;
    margin: 0 auto;
  }

  .table-line {
    width: 100%;
    height: 6px;
    background: #f5f6f7;
    margin: 0 0 15px;
  }

  .table-content {

    padding: 0 40px;

    .top-info {
      .t1 {
        margin-right: 30px;
      }

      .t2 {
        color: #999999;
      }

      .center-i {
        // margin: 0 40px;
      }

      .right-img {
        margin-right: 10%;
      }

      .userName {
        margin-left: 10px;
      }

      .info-div {
        margin-top: 10px;
      }
    }

    .title {
      height: 60px;
      line-height: 60px;
      font-weight: bold;
    }

    ::v-deep .el-table__row>td {
      /* 去除表格线 */
      // border: none !important;
    }

    ::v-deep .el-table th.is-leaf {
      /* 去除上边框 */
      border: none !important;
    }

    ::v-deep .el-table::before {
      /* 去除下边框 */
      height: 0 !important;
    }

    ::v-deep .el-table .el-table__body-wrapper .el-table__cell {
      padding: 10px 0 !important;
    }

    .my-table {
      border: 1px solid #ebeef5;
      padding: 10px 5px;
    }

    .el-form-item .el-form-item {
      margin-bottom: 20px;
    }

    .leftInput {
      width: 100%;
      position: relative;
    }

    .rightTip {
      position: absolute;

      top: 0;
      z-index: 2;
      color: #999999;
    }

    ::v-deep .el-input-number .el-input__inner {
      text-align: left;
    }

    .numDiv {
      padding: 15px;
      background: #FAFAFA;
      border: 1px solid #E6E6E6;
      border-radius: 8px;
      margin: 20px 0;

      .right-num {
        // margin-right: 10%;
      }

      .center-num {
        // margin: 0 20px;
      }

      .t1 {
        margin-right: 20px;
      }

      .t2 {
        color: #999999;
      }

      .t3 {
        color: #FE0000;
      }

      .num1 {
        margin: 0 10px;
      }

      ::v-deep .num2 .el-input__inner {
        color: #FF0000;
      }

      .inputNum {
        color: #FF0000;
      }
    }

    .infoDiv {
      // margin: 0 20px;

      .t1 {
        margin-right: 20px;
      }

      .t2 {
        color: #5C83F2
      }
    }
  }
}
</style>
